//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysNoticeDetail } from '@/api/modular/system/noticeManage'

export default {
    name: 'DetailForm',
    components: {},

    data() {
        return {
            visible: false,
            confirmLoading: false,
            contentRecord: '',
        }
    },

    methods: {
        // 初始化方法
        detail(record) {
            this.confirmLoading = true
            this.visible = true
            this.sysNoticeDetail(record.id)
        },

        /**
         * 查看詳情
         */
        sysNoticeDetail(id) {
            sysNoticeDetail({ id: id }).then((res) => {
                this.confirmLoading = false
                this.contentRecord = res.data
            })
        },

        handleCancel() {
            this.visible = false
        },
    },
}
